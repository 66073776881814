<template>
  <div>
    <v-app-bar style="background-color: rgba(255, 255, 255, 0.9)!important; box-shadow: 0px -5px 20px #e8e8e8!important;" id="home-app-bar" app color="white" elevation="0" height="56">
      <div
        @click="toHomepage()"
        style="cursor: pointer; display: contents; margin-right: 16px;"
      >
        <LogoIcon
          style="margin-left: -8px; margin-right: 8px; max-width: 40px;"
        />
        <LogoText />
      </div>
      <v-spacer />
      <div style="padding-right: 8px;" class="hidden-sm-and-down">
        <v-btn
          :ripple="false"
          class="font-weight-bold"
          style="pointer-events: none; color: rgba(0, 0, 0, 0.54)!important;"
          min-width="96"
          text
        >
          +372 5880<span style="opacity: 0;">i</span>5006
        </v-btn>
        <v-btn
          v-for="(name, i) in items"
          :key="i"
          :to="{ name }"
          :exact="name === 'Home'"
          class="font-weight-bold"
          style="padding: 8px; color: rgba(0, 0, 0, 0.54)!important;"
          text
        >
          {{ name }}
        </v-btn>
      </div>  
      <v-btn
        v-if="screenWidth >= 460"
        style="border-radius: 5px; font-weight: bold;"
        dark
        color="#aa004c"
        elevation="0"
        to="sign-up"
      >
        <span>{{ $t("appBar.tryFree") }}</span
        >
      </v-btn>
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on"
            ><flag
              style="font-size:20px; width: 30px; border: 2px solid #d1d1d1;"
              v-bind:squared="false"
              :iso="languageFlag"
              v-on="on"
          /></v-btn>
        </template>
        <v-list>
          <v-list-item class="languageRow" v-for="(item, i) in languages" :key="i + 'A'">
            <v-list-item-title @click="changeLang(item.language)">
              <flag
                :key="item.title"
                style="font-size:20px; width: 30px; border: 2px solid #d1d1d1;"
                :iso="item.flag"
                v-bind:squared="false"
              />
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
    </v-app-bar>

    <home-drawer v-model="drawer" :items="items" />
  </div>
</template>

<script>
import Vue from "vue";
import FlagIcon from "vue-flag-icon";
//import VueI18n from "vue-i18n";

/*const i18n = new VueI18n({
  locale: "en",
  messages: { en: { title: "Title" } }
});*/

Vue.use(FlagIcon);

export default {
  name: "HomeAppBar",
  methods: {
    toHomepage() {
      window.location.href = "/";
    },
    test() {
      console.log("test")
    },
    changeLang(lang) {
      for (let i = 0; i < this.languages.length; i++) {
        if (this.languages[i].language === lang) {
          this.shortTitle = this.languages[i].shortTitle;
          this.languageTitle = this.languages[i].title;
          this.languageFlag = this.languages[i].flag;
          this.$i18n.locale = this.languages[i].language;
          break;
        }
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.test());
    /*Notification.requestPermission().then(function(result) {
      const title = "Actions Notification";
      const options = {
        actions: [
          {
            action: "coffee-action",
            title: "Coffee",
            icon: "/demos/notification-examples/images/action-1-128x128.png"
          },
          {
            action: "doughnut-action",
            title: "Doughnut",
            icon: "/demos/notification-examples/images/action-2-128x128.png"
          },
          {
            action: "gramophone-action",
            title: "gramophone",
            icon: "/demos/notification-examples/images/action-3-128x128.png"
          },
          {
            action: "atom-action",
            title: "Atom",
            icon: "/demos/notification-examples/images/action-4-128x128.png"
          }
        ]
      };

      const maxVisibleActions = Notification.maxActions;
      if (maxVisibleActions < 4) {
        options.body =
          `This notification will only display ` +
          `${maxVisibleActions} actions.`;
      } else {
        options.body =
          `This notification can display up to ` +
          `${maxVisibleActions} actions.`;
      }

      serviceWorkerRegistration.showNotification(title, options);
    });*/
  },
  computed: {
    screenWidth: {
      get() {
        return this.$store.state.screenWidth;
      },
      set(newValue) {
        return this.$store.dispatch("setScreenWidth", newValue);
      }
    },
    items () {
      //https://forum.vuejs.org/t/what-is-clean-scalable-way-to-translate-routes-in-vue-js-using-vue-router-according-to-the-app-active-locale/95340
      return [this.$t("appBar.benefits"), this.$t("appBar.features"), this.$t("appBar.pricing"), this.$t("appBar.contact")]
    }
  },
  beforeMount() {
  },
  components: {
    HomeDrawer: () => import("./Drawer"),
    LogoText: () => import("@/components/LogoText.vue"),
    LogoIcon: () => import("@/components/LogoIcon.vue"),
    PluginDemo: () => import("@/components/PluginDemo.vue")
  },
  data: () => ({
    drawer: null,
    shortTitle: "EN",
    languageFlag: "gb",
    languageTitle: "English",
    languages: [
      {
        flag: "gb",
        language: "en",
        title: "English",
        shortTitle: "EN"
      },
      {
        flag: "ee",
        language: "ee",
        title: "Eesti",
        shortTitle: "EE"
      }
    ]
  })
};
</script>

<style>
.languageRow:hover {
  background-color: rgb(239 239 239);
}

.languageRow {
  pointer-events: auto;
  cursor: pointer;
}
</style>
